import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";


import grafSvg1 from "./assets/img/graf.svg";
import InputMini from "../../components/InputMini";
import Drop from "../personal/components/Drop";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(0);


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Подбор персонала
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    График встреч
                </p>

            </div>
        )}>

            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            График встреч
                        </b>
                    </div>
                </div>
            </div>

            <div className="workplace-space">
                <div className="filter-box__inputs filter-box__inputs-time"
                     style={{width: "100%", justifyContent: "flex-end"}}>
                    <div className="work-time-input">
                        <InputMini type={'date'} center={true} name={'Период с'} placeholder={'01.01.2000'}/>
                    </div>
                    <p style={{margin: "0 1rem"}}></p>
                    <div className="work-time-input ">
                        <InputMini type={'date'} center={true} name={'Период по:'} placeholder={'01.01.2000'}/>
                    </div>
                </div>
            </div>

            <p style={{marginTop: "7rem"}}></p>
            {
                [
                    '14 октября 2024, понедельник',
                    '15 октября 2024, вторник',
                    '16 октября 2024, вторник',
                    '17 октября 2024, вторник',
                    '18 октября 2024, вторник',
                    '19 октября 2024, вторник',
                    '20 октября 2024, вторник',
                ].map((itemMaps) => {
                    return (
                        <>
                            <Drop name={itemMaps} drop={() => {
                                return (
                                    <div className={'drop-list'}>
                                        <img src={grafSvg1}/>
                                    </div>
                                )
                            }}
                            />
                            <p style={{padding: "3rem 0"}}></p>
                        </>
                    )
                })
            }

        </Layout>
    );


}