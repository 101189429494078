import './scss/index.scss';

// @ts-ignore
import InputMini from "../../../../components/InputMini";
import TableCustom from "../../../../components/TableCustom";
// @ts-ignore
import table_ana from "./assets/img/table_ana.svg";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'work-time'}>
            <div className="filter-box filter-box-p0 filter-box-register">

                <div className="filter-box__inputs filter-box__inputs-time">
                    <div className="work-time-input work-time-input-i11">
                        <InputMini name={'Период с'} placeholder={'5-и дневка'}/>
                    </div>
                    <p style={{margin: "0 1rem"}}></p>
                    <div className="work-time-input work-time-input-i22">
                        <InputMini name={'по'} placeholder={'Выбрать месяц'}/>
                    </div>

                    <div className="btn-head-el btn-head-el-line" style={{marginLeft: "auto"}}>
                        <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                        <div className="btn btn-create text text-s14"> Применить</div>
                    </div>
                </div>


            </div>
            <p style={{padding: "4rem 0"}}></p>
            <img src={table_ana} style={{width: "100%"}}/>


        </div>
    );

}

export default Empty;