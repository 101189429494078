import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";
import BoxNav from "../../components/BoxNav";

import svg1 from "./assets/icon/1.svg";
import svg2 from "./assets/icon/2.svg";
import svg3 from "./assets/icon/3.svg";
import svg4 from "./assets/icon/4.svg";

import donwload from "./assets/icon/donwload.svg";

import history from "./assets/img/history.svg";

import TableCustom from "../../components/TableCustom";
import plusImg from "../../assets/images/plus.svg";
import plus from "../registry/assets/icon/plus.svg";
import Work_time from "./components/work_time";
import Visit from "./components/visit";
import Analytics from "./components/analytics";

// @ts-ignore
import color_people from "../dashboard/assets/icon/fluent-emoji-color_people-home-32.svg";
// @ts-ignore
import emoji_airplane from "../dashboard/assets/icon/fluent-emoji_airplane.svg";
// @ts-ignore
import emoji_beach from "../dashboard/assets/icon/fluent-emoji_beach-with-umbrella.svg";
// @ts-ignore
import emoji_dollar from "../dashboard/assets/icon/fluent-emoji_dollar-banknote.svg";
// @ts-ignore
import emoji_stethoscope from "../dashboard/assets/icon/fluent-emoji_stethoscope.svg";
// @ts-ignore
import icon_table from "../dashboard/assets/icon/icon-park_table.svg";
// @ts-ignore
import exit_table from "../dashboard/assets/icon/exit_table.svg";
// @ts-ignore
import filterSvg from "../dashboard/assets/icon/filter.svg";

import InputMini from "../../components/InputMini";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(0);


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Кадровое администрирование
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Отсутствия сотрудников
                </p>

            </div>
        )}>

            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            Отсутствия сотрудников
                        </b>
                    </div>
                </div>
            </div>

            <BoxNav imageType={true} step={step} onStep={(st) => {
                setStep(st);
            }} content={[
                {
                    name: 'График отпусков',
                    icon: icon_table,
                    content: (<>
                        <div className="filter-box filter-box-p0 filter-box-register">
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14"><img src={filterSvg}
                                                                                   style={{width: "2rem",marginRight:"0.5rem"}}/>Фильтр
                                </div>
                                <div className="btn btn-create text text-s14"><img src={plus}/> Перезаполнить документ
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="filter-box__inputs filter-box__inputs-time"
                                 style={{justifyContent: "space-between"}}>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Отобразить'} placeholder={'Все сотрудники'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Сотрудник'} placeholder={'Укажите сотрудника'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Года'} placeholder={'2023-2024'}/>
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                                <div className="btn btn-create text text-s14"> Применить</div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                        </div>
                        <img src={exit_table} style={{width: "100%"}}/>


                        <p style={{
                            marginTop: "1.2rem",
                        }}></p>

                        <div className="news-box-filter">
                            <div className="btn-head-el btn-head-el-mini">
                                <div className="btn btn-time text text-s14"><img src={donwload}/> Экспорт Excel</div>
                            </div>
                        </div>


                    </>)
                },
                {
                    name: 'Отпуск',
                    icon: emoji_beach,
                    content: (<>
                        <div className="filter-box filter-box-p0 filter-box-register">
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14"><img src={filterSvg}
                                                                                   style={{width: "2rem",marginRight:"0.5rem"}}/>Фильтр
                                </div>
                                <div className="btn btn-create text text-s14"><img src={plus}/> Перезаполнить документ
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="filter-box__inputs filter-box__inputs-time"
                                 style={{justifyContent: "space-between"}}>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Отобразить'} placeholder={'Все сотрудники'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Сотрудник'} placeholder={'Укажите сотрудника'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Года'} placeholder={'2023-2024'}/>
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                                <div className="btn btn-create text text-s14"> Применить</div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                        </div>
                        <img src={exit_table} style={{width: "100%"}}/>


                        <p style={{
                            marginTop: "1.2rem",
                        }}></p>

                        <div className="news-box-filter">
                            <div className="btn-head-el btn-head-el-mini">
                                <div className="btn btn-time text text-s14"><img src={donwload}/> Экспорт Excel</div>
                            </div>
                        </div>


                    </>)
                },
                {
                    name: 'Без содержания',
                    icon: emoji_dollar,
                    content: (<>
                        <div className="filter-box filter-box-p0 filter-box-register">
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14"><img src={filterSvg}
                                                                                   style={{width: "2rem",marginRight:"0.5rem"}}/>Фильтр
                                </div>
                                <div className="btn btn-create text text-s14"><img src={plus}/> Перезаполнить документ
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="filter-box__inputs filter-box__inputs-time"
                                 style={{justifyContent: "space-between"}}>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Отобразить'} placeholder={'Все сотрудники'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Сотрудник'} placeholder={'Укажите сотрудника'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Года'} placeholder={'2023-2024'}/>
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                                <div className="btn btn-create text text-s14"> Применить</div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                        </div>
                        <img src={exit_table} style={{width: "100%"}}/>


                        <p style={{
                            marginTop: "1.2rem",
                        }}></p>

                        <div className="news-box-filter">
                            <div className="btn-head-el btn-head-el-mini">
                                <div className="btn btn-time text text-s14"><img src={donwload}/> Экспорт Excel</div>
                            </div>
                        </div>


                    </>)
                },
                {
                    name: 'Больничные',
                    icon: emoji_stethoscope,
                    content: (<>
                        <div className="filter-box filter-box-p0 filter-box-register">
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14"><img src={filterSvg}
                                                                                   style={{width: "2rem",marginRight:"0.5rem"}}/>Фильтр
                                </div>
                                <div className="btn btn-create text text-s14"><img src={plus}/> Перезаполнить документ
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="filter-box__inputs filter-box__inputs-time"
                                 style={{justifyContent: "space-between"}}>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Отобразить'} placeholder={'Все сотрудники'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Сотрудник'} placeholder={'Укажите сотрудника'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Года'} placeholder={'2023-2024'}/>
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                                <div className="btn btn-create text text-s14"> Применить</div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                        </div>
                        <img src={exit_table} style={{width: "100%"}}/>


                        <p style={{
                            marginTop: "1.2rem",
                        }}></p>

                        <div className="news-box-filter">
                            <div className="btn-head-el btn-head-el-mini">
                                <div className="btn btn-time text text-s14"><img src={donwload}/> Экспорт Excel</div>
                            </div>
                        </div>


                    </>)
                },
                {
                    name: 'Командировки',
                    icon: emoji_airplane,
                    content: (<>
                        <div className="filter-box filter-box-p0 filter-box-register">
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14"><img src={filterSvg}
                                                                                   style={{width: "2rem",marginRight:"0.5rem"}}/>Фильтр
                                </div>
                                <div className="btn btn-create text text-s14"><img src={plus}/> Перезаполнить документ
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="filter-box__inputs filter-box__inputs-time"
                                 style={{justifyContent: "space-between"}}>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Отобразить'} placeholder={'Все сотрудники'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Сотрудник'} placeholder={'Укажите сотрудника'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Года'} placeholder={'2023-2024'}/>
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                                <div className="btn btn-create text text-s14"> Применить</div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                        </div>
                        <img src={exit_table} style={{width: "100%"}}/>


                        <p style={{
                            marginTop: "1.2rem",
                        }}></p>

                        <div className="news-box-filter">
                            <div className="btn-head-el btn-head-el-mini">
                                <div className="btn btn-time text text-s14"><img src={donwload}/> Экспорт Excel</div>
                            </div>
                        </div>


                    </>)
                },
                {
                    name: 'Онлайн',
                    icon: color_people,
                    content: (<>
                        <div className="filter-box filter-box-p0 filter-box-register">
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14"><img src={filterSvg}
                                                                                   style={{width: "2rem",marginRight:"0.5rem"}}/>Фильтр
                                </div>
                                <div className="btn btn-create text text-s14"><img src={plus}/> Перезаполнить документ
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="filter-box__inputs filter-box__inputs-time"
                                 style={{justifyContent: "space-between"}}>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Отобразить'} placeholder={'Все сотрудники'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Сотрудник'} placeholder={'Укажите сотрудника'}/>
                                </div>
                                <div className="work-time-input work-time-input-i33">
                                    <InputMini name={'Года'} placeholder={'2023-2024'}/>
                                </div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                                <div className="btn btn-create text text-s14"> Применить</div>
                            </div>
                            <p style={{margin: "2rem 0"}}></p>
                        </div>
                        <img src={exit_table} style={{width: "100%"}}/>


                        <p style={{
                            marginTop: "1.2rem",
                        }}></p>

                        <div className="news-box-filter">
                            <div className="btn-head-el btn-head-el-mini">
                                <div className="btn btn-time text text-s14"><img src={donwload}/> Экспорт Excel</div>
                            </div>
                        </div>


                    </>)
                }
            ]}/>


        </Layout>
    );


}