import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";

import basilSvg from "./assets/icon/basil_notification-on-outline.svg";


import TableCustom from "../../components/TableCustom";
import CheckBox from "../../components/CheckBox";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(0);
    const [active, setActive] = useState({});


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Онбординг
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Организация рабочего места
                </p>

            </div>
        )}>

            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            Организация рабочего места
                        </b>
                    </div>
                </div>
            </div>

            <div className="workplace-space">
                <TableCustom
                    whType={'rem'}
                    th={{
                        1: {
                            name: '',
                            w: 7,

                            option: ((row) => {

                                return (
                                    <CheckBox onClick={() => {
                                        setActive({...active, ...{[row[1]]: row[1] in active ? !active[row[1]] : true}})
                                    }} active={row[1] in active && active[row[1]]}/>
                                )
                            })
                        },
                        2: {
                            name: 'Чек-лист',
                            w: 94,
                            thClassName: "big-color-th",
                            option: null
                        },
                        3: {
                            name: 'Исполнитель',
                            w: 17.4,
                            option: null
                        },
                        4: {
                            name: 'Статус',
                            w: 17.4,
                            option: ((row) => {
                                return (
                                    <div className="task-option">
                                        <a className={'console-post-list-create console-post-list-create-bly text text-s12'}> {row[4]} </a>
                                    </div>
                                )
                            })
                        },
                        5: {
                            name: 'Напомнить',
                            w: 17.4,
                            option: ((row) => {
                                return (
                                    <img style={{width: "2.4rem"}} src={basilSvg}/>
                                )
                            })
                        },

                    }}
                    tr={
                        [
                            {
                                1: "1",
                                2: "Страницы все собраны",
                                3: "Жанатова Шынар",
                                4: "В работе",
                                5: "12.12.2024",
                            },
                            {
                                1: "2",
                                2: "Страницы все собраны",
                                3: "Жанатова Шынар",
                                4: "В работе",
                                5: "12.12.2024",
                            },
                            {
                                1: "3",
                                2: "Страницы все собраны",
                                3: "Жанатова Шынар",
                                4: "В работе",
                                5: "12.12.2024",
                            },
                            {
                                1: "4",
                                2: "Страницы все собраны",
                                3: "Жанатова Шынар",
                                4: "В работе",
                                5: "12.12.2024",
                            },
                            {
                                1: "5",
                                2: "Страницы все собраны",
                                3: "Жанатова Шынар",
                                4: "В работе",
                                5: "12.12.2024",
                            }, {
                            1: "6",
                            2: "Страницы все собраны",
                            3: "Жанатова Шынар",
                            4: "В работе",
                            5: "12.12.2024",
                        },
                            {
                                1: "7",
                                2: "Страницы все собраны",
                                3: "Жанатова Шынар",
                                4: "В работе",
                                5: "12.12.2024",
                            },

                        ]
                    }
                />
            </div>
        </Layout>
    );


}