import './scss/index.scss';

// @ts-ignore
import check from "./assets/icon/check.svg";

interface Props {
    active: boolean;
    onClick?: Function;
}

const Empty: React.FC<Props> = ({active = true, onClick}) => {


    return (
        <div onClick={() => {
            if (onClick) {
                onClick();
            }
        }} className={'checkbox-input'}>
            {
                active && (<img src={check}/>)
            }
        </div>
    );

}

export default Empty;