import './scss/index.scss';

import Drop from "../Drop";
// @ts-ignore
import print from "../Drop/assets/icon/print.svg";
// @ts-ignore
import upload from "../Drop/assets/icon/upload.svg";
// @ts-ignore
import donwload from "../Drop/assets/icon/donwload.svg";
// @ts-ignore
import documentColor from "../Drop/assets/icon/document-color.svg";

interface Props {
    name?: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'personal-my'}>
            <Drop name={'Личная информация'} drop={() => {
                return (
                    <div className={'drop-list'}>
                        <div className="drop-item">
                            <div className="drop-item-name">Удостоверение личности</div>
                            <div className="drop-item-value text text-gray text-s16">
                                <img className={'icon-about'} src={print}/>
                                <img className={'icon-about'} src={upload}/>
                                <img className={'icon-about'} src={donwload}/>
                            </div>
                        </div>
                        <div className="drop-item">
                            <div className="drop-item-name">Диплом</div>
                            <div className="drop-item-value text text-gray text-s16">
                                <img className={'icon-about'} src={print}/>
                                <img className={'icon-about'} src={upload}/>
                                <img className={'icon-about'} src={donwload}/>
                            </div>
                        </div>
                        <div className="drop-item">
                            <div className="drop-item-name">Адрес</div>
                            <div className="drop-item-value text text-gray text-s16">г. Алматы район Жетысу, дом 15
                            </div>
                        </div>
                        <div className="drop-item">
                            <div className="drop-item-name">Счет KZ</div>
                            <div className="drop-item-value text text-gray text-s16">0084763TFYG82910KZ</div>
                        </div>
                    </div>
                )
            }}
            />
            <Drop name={'Рабочая информация'} drop={() => {
                return (
                    <div className={'drop-list'}>
                        <div className="drop-item">
                            <div className="drop-item-name">Резюме</div>
                            <div className="drop-item-value text text-gray text-s16">
                                <img className={'icon-about'} src={print}/>
                                <img className={'icon-about'} src={upload}/>
                                <img className={'icon-about'} src={donwload}/>
                            </div>
                        </div>
                        <div className="drop-item">
                            <div className="drop-item-name">Job Offer</div>
                            <div className="drop-item-value text text-gray text-s16">
                                <img className={'icon-about'} src={print}/>
                                <img className={'icon-about'} src={upload}/>
                                <img className={'icon-about'} src={donwload}/>
                            </div>
                        </div>

                        <div className="drop-item">
                            <div className="drop-item-name">Дата приема</div>
                            <div className="drop-item-value text text-gray text-s16">15.07.2024</div>
                        </div>
                        <div className="drop-item">
                            <div className="drop-item-name">Корпоративная почта</div>
                            <div className="drop-item-value text text-gray text-s16">qwery123@gmail.com</div>
                        </div>
                        <div className="drop-item">
                            <div className="drop-item-name">Контакты</div>
                            <div className="drop-item-value text text-gray text-s16">+7 (700) 077 00 17</div>
                        </div>
                        <div className="drop-item">
                            <div className="drop-item-name">Сертификаты</div>
                            <div className="drop-item-value text text-gray text-s16">
                                <img className={'icon-about'} src={print}/>
                                <img className={'icon-about'} src={upload}/>
                                <img className={'icon-about'} src={donwload}/>
                            </div>
                        </div>
                    </div>
                )
            }}/>
            <Drop name={'Документы'} drop={() => {
                return (
                    <div className={'drop-list'}>
                        <div className="upload-file">
                            <div className="upload-file-icon"
                                 style={{backgroundImage: "url(" + documentColor + ")"}}></div>
                            <div className="upload-file-name">
                                <p className={'text text-s18'}>
                                    Договора
                                </p>
                                <p style={{margin: "0.25rem 0"}}></p>
                                <p className={'text text-gray text-s14'}>
                                    20 FIles
                                </p>
                            </div>
                            <div className="upload-info">
                                <p className={'text text-s14'}>
                                    15.0 Gb
                                </p>
                            </div>

                        </div>
                    </div>
                )
            }}/>
        </div>
    );

}

export default Empty;