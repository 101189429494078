import './scss/index.scss';
// @ts-ignore
import plus from "../../../registry/assets/icon/plus.svg";

// @ts-ignore
import one1 from "./assets/img/1.png";
// @ts-ignore
import one2 from "./assets/img/2.png";
// @ts-ignore
import one3 from "./assets/img/3.png";
// @ts-ignore
import one4 from "./assets/img/4.png";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'news-box'}>
            <div className="news-box-filter">
                <div className="btn-head-el btn-head-el-mini">
                    <div className="btn btn-create text text-s14"><img src={plus}/> Создать</div>
                </div>
            </div>

            <div className="news-box-list">

                {
                    [
                        {
                            name: "История компании",
                            text: "А вот и то, что называется “лендинговый текст о компании”. Здесь уже есть и и продающие блоки, и выгоды, и особенности, и вообще много чего. По уровню сложности такой контент почти идентичен сложности создания Landing Page, а потому стоит больше обычных аналогичных материалов.",
                            img: one1
                        },
                        {
                            name: "Ценности и Миссия",
                            text: "А вот и то, что называется “лендинговый текст о компании”. Здесь уже есть и и продающие блоки, и выгоды, и особенности, и вообще много чего. По уровню сложности такой контент почти идентичен сложности создания Landing Page, а потому стоит больше обычных аналогичных материалов.",
                            img: one2
                        },
                        {
                            name: "Команда",
                            text: "А вот и то, что называется “лендинговый текст о компании”. Здесь уже есть и и продающие блоки, и выгоды, и особенности, и вообще много чего. По уровню сложности такой контент почти идентичен сложности создания Landing Page, а потому стоит больше обычных аналогичных материалов.",
                            img: one3
                        },
                        {
                            name: "Мы в цифрах",
                            text: "А вот и то, что называется “лендинговый текст о компании”. Здесь уже есть и и продающие блоки, и выгоды, и особенности, и вообще много чего. По уровню сложности такой контент почти идентичен сложности создания Landing Page, а потому стоит больше обычных аналогичных материалов.",
                            img: one4
                        }
                    ].map((newsSingle) => {
                        return (
                            <div className="news-post">
                                <div className="news-post-img" style={{backgroundImage:"url("+newsSingle.img+")"}}></div>
                                <div className="news-post-text">
                                    <div className="news-post-name text text-s36">
                                        {newsSingle.name}
                                    </div>
                                    <div className="news-post-desk text text-s22">
                                        {newsSingle.text}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    );

}

export default Empty;