import './scss/index.scss';

// @ts-ignore
import searchImg from "./assets/icon/search.svg";

interface Props {
    name?: string;
    placeholder?: string;
    type?: string;
    search?: boolean;
    center?: boolean;
}

const Empty: React.FC<Props> = ({name, placeholder,type='text', search = false, center = false}) => {


    return (
        <div className={"input " + (search && 'input-search') + (center && ' input-center ')}>
            {
                search && (
                    <div className="icon-search-input" style={{backgroundImage: "url(" + searchImg + ")"}}></div>
                )
            }

            {
                name && (
                    <div className="input-label text text-gray2 text-s14">
                        {name}
                    </div>
                )
            }
            <input type={type} placeholder={placeholder} className="input-in text text-s14"/>
        </div>
    );

}

export default Empty;