import './scss/index.scss';


// @ts-ignore
import svg1 from "./assets/img/1.svg";
// @ts-ignore
import svg2 from "./assets/img/2.svg";


interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'page-personal-border'}>
            <div className="page-personal-border-site-bar">
                <img style={{width: "100%"}} src={svg2}/>
            </div>
            <div className="page-personal-border-main">
                <img style={{width: "100%"}} src={svg1}/>

            </div>
        </div>
    );

}

export default Empty;