import './scss/index.scss';

// @ts-ignore
import React, {useState} from 'react';

// @ts-ignore
import moment from 'moment';


// @ts-ignore
import left from './assets/icon/left.svg';
// @ts-ignore
import right from './assets/icon/right.svg';

import 'moment/locale/ru'; // Локализация на русский (если необходимо)

interface Props {
    name?: string;
}

const Empty: React.FC<Props> = ({name}) => {

    const [currentMonth, setCurrentMonth] = useState(moment());

    const startOfMonth = currentMonth.clone().startOf('month');
    const endOfMonth = currentMonth.clone().endOf('month');

    // Исправление: теперь старт недели - понедельник, конец - воскресенье
    const startOfWeek = startOfMonth.clone().startOf('week').isoWeekday(1); // Понедельник
    const endOfWeek = endOfMonth.clone().endOf('week').isoWeekday(7); // Воскресенье

    // Дни недели (начало с понедельника)
    const weekdays = moment.weekdaysShort(true);

    // Генерация дней в месяце, включая начало и конец недели
    const generateDaysInMonth = () => {
        const days = [];
        let day = startOfWeek.clone();

        while (day.isBefore(endOfWeek, 'day') || day.isSame(endOfWeek, 'day')) {
            days.push(
                <div key={day.format('DD-MM-YYYY')} className="day text text-s14">
                    {day.format('D')}
                </div>
            );
            day.add(1, 'days');
        }
        return days;
    };

    const previousMonth = () => {
        setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
    };

    const nextMonth = () => {
        setCurrentMonth(currentMonth.clone().add(1, 'month'));
    };

    return (
        <div className={'calendar-box'}>
            <div className="calendar">
                <div className="calendar-header">
                    <button className={'icon-calendar-page'} onClick={previousMonth}><img src={left}/></button>
                    <h2><b  className={'text text-s20'}>{currentMonth.format('MMMM YYYY')}</b></h2>
                    <button className={'icon-calendar-page'} onClick={nextMonth}><img src={right}/></button>
                </div>

                {/* Отображаем названия дней недели */}
                <div className="weekdays">
                    {weekdays.map((day) => (
                        <div key={day} className="weekday text text-s10 text-gray">
                            {day}
                        </div>
                    ))}
                </div>

                <div className="calendar-grid">
                    {generateDaysInMonth()}
                </div>
            </div>
        </div>
    );

}

export default Empty;