import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";
import BoxNav from "../../components/BoxNav";

import svg1 from "./assets/icon/1.svg";
import svg2 from "./assets/icon/2.svg";
import svg3 from "./assets/icon/3.svg";
import svg4 from "./assets/icon/4.svg";
import svg5 from "./assets/icon/5.svg";
import svg6 from "./assets/icon/6.svg";
import svg7 from "./assets/icon/7.svg";
import svg8 from "./assets/icon/8.svg";


import user_img from "./assets/img/user.png";


import icon1 from "./assets/icon/icon1.svg";
import icon2 from "./assets/icon/icon2.svg";
import icon3 from "./assets/icon/icon3.svg";
import About from "./components/About";
import Work from "./components/Work";
import Out from "./components/Out";
import Task from "./components/Task";
import Meetings from "./components/Meetings";
import Trainer from "./components/Trainer";
import Document from "./components/document";
import Border from "./components/border";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(0);


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Кадровое администрирование
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Личные карточки сотрудников
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Жанатова Шынар
                </p>
            </div>
        )}>


            <div className="personal">
                <div className="personal-bk"></div>
                <div className="personal-user">

                    <div className="personal-img" style={{backgroundImage:"url("+user_img+")"}}>

                    </div>
                    <div className="personal-info">
                        <div className="personal-info-name">
                            <p className={'text text-s24'}>
                                <b>Жанатова Шынар</b>
                            </p>
                        </div>
                        <div className="personal-info-pos">
                            <p className={'text text-s16 text-gray'}>
                                HR-менеджер
                            </p>
                        </div>
                        <div className="personal-info-contact">
                            <div className="personal-info-contact-item">
                                <div className="personal-icon">
                                    <div className="personal-icon-src"
                                         style={{backgroundImage: "url(" + icon1 + ")"}}></div>
                                    <div className="personal-icon-value text text-s14">+7(700) 077 0017</div>
                                </div>
                                <div className="personal-icon">
                                    <div className="personal-icon-src"
                                         style={{backgroundImage: "url(" + icon2 + ")"}}></div>
                                    <div className="personal-icon-value text text-s14">angelamoss@mail.com</div>
                                </div>
                                <div className="personal-icon">
                                    <div className="personal-icon-src"
                                         style={{backgroundImage: "url(" + icon3 + ")"}}></div>
                                    <div className="personal-icon-value text text-s14">https://www.linkedin.com/</div>
                                </div>
                            </div>
                            <div className="personal-info-contact-item">
                                <p className={' text text-s18'}>
                                    <b>Стаж работы</b>
                                </p>
                                <p className={' text text-s14'}>
                                    0 Года <br/>
                                    0 месяцев <br/>
                                    2 дня
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <BoxNav step={step} onStep={(st) => {
                setStep(st);
            }} content={[
                {
                    name: 'Обо мне',
                    icon: svg8,
                    content: <About name={'s'}/>
                },
                {
                    name: 'Работа',
                    icon: svg4,
                    content: <Work name={''}/>
                },
                {
                    name: 'Отсутствия',
                    icon: svg1,
                    content: <Out name={''}/>
                },
                {
                    name: 'Мои задачи',
                    icon: svg5,
                    content: <Task name={''}/>
                },
                {
                    name: 'Мои встречи',
                    icon: svg3,
                    content: <Meetings name={''}/>
                },
                {
                    name: 'Мои обучения',
                    icon: svg7,
                    content: <Trainer name={''}/>
                },
                {
                    name: 'Мои документы',
                    icon: svg6,
                    content: <Document name={''}/>
                },
                {
                    name: 'Онбоардинг',
                    icon: svg2,
                    content: <Border name={''}/>
                }
            ]}/>


        </Layout>
    );


}