import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";


import contentSvg1 from "./assets/img/Content.svg";
import BoxNav from "../../components/BoxNav";

import svg1 from "./assets/icon/1.svg";
import svg2 from "./assets/icon/2.svg";
import svg3 from "./assets/icon/3.svg";
import svg4 from "./assets/icon/4.svg";
import listSvg from "./assets/img/list.svg";
import tabletwoSvg from "./assets/img/tabletwo.svg";
import biblSvg from "./assets/img/biblia.svg";
import usersSvg from "./assets/img/users.svg";
import planSvg from "./assets/img/plan.svg";
import rezervSvg from "./assets/img/rezerv.svg";
import analizSvg from "./assets/img/analiz.svg";
import plus from "../registry/assets/icon/plus.svg";

export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(0);


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Обучение
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Материалы
                </p>

            </div>
        )}>

            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            Материалы
                        </b>
                    </div>
                </div>
            </div>

            <div className="workplace-space">
                <BoxNav step={step} first={true} onStep={(st) => {
                    setStep(st);
                }} content={[
                    {
                        name: 'План развития',
                        icon: svg1,
                        content: (<div className={'curse-list-plus'}>
                            <img className={'curse-list-plus-img'} style={{width:"100%"}} src={planSvg}/>

                        </div>)
                    },
                    {
                        name: 'Анализ карьеры',
                        icon: svg2,
                        content: (<div className={'curse-list-plus'}>
                            <img className={'curse-list-plus-img'} style={{width:"135.3rem"}} src={analizSvg}/>


                        </div>)
                    },
                    {
                        name: 'Кадровый резерв',
                        icon: svg3,
                        content: (<div className={'curse-list-plus curse-list-plus-start'}>
                            <img className={'curse-list-plus-img'} style={{width: "126.3rem"}} src={rezervSvg}/>


                        </div>)
                    },
                    {
                        name: false,
                        icon: svg3,
                        content: (<div className={'curse-list-plus curse-list-plus-start'}>
                            <img className={'curse-list-plus-img'} style={{width: "100%"}} src={usersSvg}/>

                        </div>)
                    },
                ]}/>
            </div>
        </Layout>
    );


}