import './scss/index.scss';

interface Props {
    name: string;
}

// @ts-ignore
import color_people from "../../../dashboard/assets/icon/fluent-emoji-color_people-home-32.svg";
// @ts-ignore
import emoji_airplane from "../../../dashboard/assets/icon/fluent-emoji_airplane.svg";
// @ts-ignore
import emoji_beach from "../../../dashboard/assets/icon/fluent-emoji_beach-with-umbrella.svg";
// @ts-ignore
import emoji_dollar from "../../../dashboard/assets/icon/fluent-emoji_dollar-banknote.svg";
// @ts-ignore
import emoji_stethoscope from "../../../dashboard/assets/icon/fluent-emoji_stethoscope.svg";

import Drop from "../Drop";

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'out-box'}>
            <div className="out-item">
                <div className="out-item-icon">
                    <div className="out-item-icon-src" style={{backgroundImage: "url(" + emoji_beach + ")"}}>

                    </div>
                    <p style={{margin: "0 1rem"}}></p>
                    <b className={'text text-s16'}>
                        0
                    </b>
                </div>
                <div className="out-item-body">
                    <Drop edit={false} name={'2024 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2022 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2021 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>
                </div>
            </div>
            <div className="out-item">
                <div className="out-item-icon">
                    <div className="out-item-icon-src" style={{backgroundImage: "url(" + emoji_dollar + ")"}}>

                    </div>
                    <p style={{margin: "0 1rem"}}></p>
                    <b className={'text text-s16'}>
                        0
                    </b>
                </div>
                <div className="out-item-body">
                    <Drop edit={false} name={'2024 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2022 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2021 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>
                </div>
            </div>
            <div className="out-item">
                <div className="out-item-icon">
                    <div className="out-item-icon-src" style={{backgroundImage: "url(" + emoji_stethoscope + ")"}}>

                    </div>
                    <p style={{margin: "0 1rem"}}></p>
                    <b className={'text text-s16'}>
                        0
                    </b>
                </div>
                <div className="out-item-body">
                    <Drop edit={false} name={'2024 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2022 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2021 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>
                </div>
            </div>
            <div className="out-item">
                <div className="out-item-icon">
                    <div className="out-item-icon-src" style={{backgroundImage: "url(" + emoji_airplane + ")"}}>

                    </div>
                    <p style={{margin: "0 1rem"}}></p>
                    <b className={'text text-s16'}>
                        0
                    </b>
                </div>
                <div className="out-item-body">
                    <Drop edit={false} name={'2024 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2022 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2021 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>
                </div>
            </div>
            <div className="out-item">
                <div className="out-item-icon">
                    <div className="out-item-icon-src" style={{backgroundImage: "url(" + color_people + ")"}}>

                    </div>
                    <p style={{margin: "0 1rem"}}></p>
                    <b className={'text text-s16'}>
                        0
                    </b>
                </div>
                <div className="out-item-body">
                    <Drop edit={false} name={'2024 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2022 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>

                    <Drop edit={false} name={'2021 год'} drop={() => {
                        return (
                            <div className={'drop-date-out'}>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                                <b className={'text text-s12'}>
                                    01.02.2024 - 14.02.2024 (14)
                                </b>
                            </div>
                        );
                    }}/>
                </div>
            </div>


        </div>
    );

}

export default Empty;