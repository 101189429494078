import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";
import BoxNav from "../../components/BoxNav";
import Org from "./assets/img/org.svg";
import lucide_building from "../../components/BoxNav/assets/icon/lucide_building.svg";
import clarity_employee from "../../components/BoxNav/assets/icon/clarity_employee-group-solid.svg";
import lsicon_tree from "../../components/BoxNav/assets/icon/lsicon_tree-outline.svg";
import simple from "../../components/BoxNav/assets/icon/simple-line-icons_docs.svg";
import tabler_template from "../../components/BoxNav/assets/icon/tabler_template.svg";
import News from "./components/news";
import Employees from "./components/employees";
import Regulations from "./components/regulations";
import Templates from "./components/templates";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(2);


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Управление
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Орг. структура
                </p>
            </div>
        )}>
            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            О компании
                        </b>
                    </div>
                </div>
            </div>


            <BoxNav step={step} onStep={(st) => {
                setStep(st);
            }} content={[
                {
                    name: 'О компании',
                    icon: lucide_building,
                    content: <News name={''}/>
                },
                {
                    name: 'Сотрудники',
                    icon: clarity_employee,
                    content: <Employees name={''}/>
                },
                {
                    name: 'Орг. структура',
                    icon: lsicon_tree,
                    content: (<div className={'org_box'}>
                        <img src={Org}/>
                    </div>)
                },
                {
                    name: 'Регламенты',
                    icon: simple,
                    content: <Regulations name={''}/>
                },
                {
                    name: 'Шаблоны',
                    icon: tabler_template,
                    content: <Templates name={''}/>
                }
            ]}/>

        </Layout>
    );


}