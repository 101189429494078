import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";
import BoxNav from "../../components/BoxNav";

import svg1 from "./assets/icon/1.svg";
import svg2 from "./assets/icon/2.svg";
import svg3 from "./assets/icon/3.svg";
import svg4 from "./assets/icon/4.svg";

import donwload from "./assets/icon/donwload.svg";

import history from "./assets/img/history.svg";

import TableCustom from "../../components/TableCustom";
import plusImg from "../../assets/images/plus.svg";
import plus from "../registry/assets/icon/plus.svg";
import Work_time from "./components/work_time";
import Visit from "./components/visit";
import Analytics from "./components/analytics";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(0);


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Кадровое администрирование
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Личные карточки сотрудников
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    чет рабочего времени
                </p>
            </div>
        )}>

            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            Учет рабочего времени
                        </b>
                    </div>
                </div>
            </div>

            <BoxNav step={step} onStep={(st) => {
                setStep(st);
            }} content={[
                {
                    name: 'Табелирование',
                    icon: svg1,
                    content: (<>
                        <TableCustom
                            whType={'rem'}
                            th={{

                                id: {
                                    name: 'Дата',
                                    w: 12,
                                    option: null
                                },
                                date: {
                                    name: 'Документ',
                                    w: 17.4,
                                    option: null
                                },
                                controragent: {
                                    name: 'Месяц',
                                    w: 17.4,
                                    option: null
                                },
                                bin: {
                                    name: 'Изменения',
                                    w: 17.4,
                                    option: null
                                },
                                typePay: {
                                    name: 'Статус',
                                    w: 17.4,
                                    option: null
                                },
                                typePay2: {
                                    name: 'Комментарий',
                                    w: 17.4,
                                    option: null
                                },
                            }}
                            tr={
                                []
                            }
                        />


                        <p style={{
                            marginTop: "1.2rem",
                        }}></p>

                        <div className="news-box-filter">
                            <div className="btn-head-el btn-head-el-mini">
                                <div className="btn btn-time text text-s14"><img src={donwload}/> Скачать pdf</div>
                                <div className="btn btn-time text text-s14"><img src={donwload}/> Экспорт Excel</div>
                            </div>
                        </div>


                        <p style={{
                            marginTop: "3rem",
                            paddingTop: "3rem",
                            width: "100%",
                            borderTop: "1px solid #6418C3"
                        }}></p>
                        <img style={{width: "100%"}} src={history}/>


                    </>)
                },
                {
                    name: 'Графики работ',
                    icon: svg2,
                    content: <Work_time name={''}/>
                },
                {
                    name: 'Посещаемость',
                    icon: svg3,
                    content: <Visit name={''}/>
                },
                {
                    name: 'Аналитика',
                    icon: svg4,
                    content: <Analytics name={''}/>
                }
            ]}/>


        </Layout>
    );


}