import './scss/index.scss';
// @ts-ignore
import React, {useEffect, useState} from 'react';

// @ts-ignore
import ArrowsDownUp from "./arrows-down-up.svg";

interface ThObject {
    w: string; // or number, depending on the actual type of `w`
    className: string;
    name: string;
    thClassName: string;
    option: Function;
    // other properties of thObject if any
}

interface Props {
    th?: object;
    titleShow?: boolean;
    titleName?: string;
    showHead?: boolean;
    whType?: string;
    fontSize?: string;
    className?: string;
    page?: number;
    pageCount?: number;
    setPage?: Function;
    mobailHtml?: Function;
    plus?: Function;
    edit?: boolean;
    tr?: object[];
}


const Table: React.FC<Props> = ({
                                    th = {},
                                    tr = [],
                                    showHead = true,
                                    pageCount = 0,
                                    page = 0,
                                    setPage,
                                    edit,
                                    mobailHtml,
                                    plus,
                                    titleShow = false,
                                    titleName = 'Все заказы',
                                    fontSize = 'text-s12',
                                    className = '',
                                    whType = '%'
                                }) => {

    const [widthWindows, setWindowWidth] = useState();

    const myRef = React.useRef();
    const [isFixed, setIsFixed] = React.useState(false);
    const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

    const toggleRowExpansion = (rowId: number) => {
        setExpandedRows(prevState => ({
            ...prevState,
            [rowId]: !prevState[rowId]
        }));
    };

    React.useEffect(() => {
        const checkScroll = () => {
            if (myRef.current) {
                const rect = myRef.current.getBoundingClientRect();
                if (rect.top <= 0) {
                    setIsFixed(true);
                } else {
                    setIsFixed(false);
                }
            }
        };

        const elementModal = document.querySelector('.modal-order-add');
        if (document.body.className == 'ReactModal__Body--open' && elementModal) {
            elementModal.addEventListener("scroll", checkScroll);
        }

        window.addEventListener("scroll", checkScroll);

    }, [myRef]);


    const paginationList = Array.from(Array(Math.ceil(pageCount)).keys());

    return (
        <div className={'table ' + (!showHead && 'table-hide') + ' ' + className}>
            {
                titleShow && (
                    <div className="table__header">
                        <p className={'text text-s18'}>
                            {titleName}
                        </p>
                    </div>
                )
            }
            <div className="table__body">

                {showHead && (
                    <div ref={myRef} className={"table__body-header " + (isFixed && 'fixed')}>
                        <div className="table-tr">
                            {
                                Object.entries(th).map(([thKey, thObject]) => {
                                    return (
                                        <div key={thKey} style={{width: (thObject as ThObject).w + whType}}
                                             className={"table-th " + (thObject as ThObject).className + ' '+(thObject as ThObject)?.thClassName+' table-th-' + thKey}>
                                            <b style={{color:"#718096"}} className={'text ' + fontSize}>{(thObject as ThObject).name}</b>
                                            {/*<img style={{width: "1.6rem",marginLeft:"1.2rem"}} src={ArrowsDownUp}/>*/}
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="table-tr table-tr-fixed" style={{width: myRef?.current?.clientWidth + "px"}}>
                            {
                                Object.entries(th).map(([thKey, thObject]) => {
                                    // @ts-ignore
                                    return (
                                        <div key={thKey} style={{width: (thObject as ThObject).w + whType}}
                                             className={"table-th " + (thObject as ThObject).className + ' table-th-' + thKey}>
                                            <span className={'text ' + fontSize}>{(thObject as ThObject).name}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )}
                <div className="table__body-main">

                    {
                        tr?.map((trObject, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <div
                                        className={"table-tr " + (parseInt(trObject?.product_store) < 0 && 'table-tr-error')}
                                        onClick={() => {
                                            if (trObject?.expandable) {
                                                toggleRowExpansion(index);
                                            }
                                        }}>
                                        {
                                            Object.entries(th).map(([thKey, thObject]) => {
                                                if ((thObject as ThObject)?.option) {
                                                    return (
                                                        <div key={thKey} onClick={() => {
                                                            if (edit && (thKey == 'name' || thKey == 'photo')) {
                                                                edit(trObject, index);
                                                            }
                                                        }} style={{width: (thObject as ThObject).w + whType}}
                                                             className={"table-th " + (thObject as ThObject).className + ' table-th-' + thKey}>
                                                            {(thObject as ThObject).option(trObject, thObject, trObject && (thKey in trObject && trObject[thKey]), thKey)}
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <div key={thKey} onClick={() => {
                                                        if (edit && (thKey == 'name' || thKey == 'photo')) {
                                                            edit(trObject, index);
                                                        }
                                                    }} style={{width: (thObject as ThObject).w + whType}}
                                                         className={"table-th " + (thObject as ThObject).className + ' table-th-' + thKey}>
                                    <b className={'text ' + fontSize}
                                          dangerouslySetInnerHTML={{__html: thKey in trObject && trObject[thKey]}}></b>
                                                    </div>
                                                );
                                            })
                                        }

                                        {plus && plus(trObject, index)}
                                    </div>
                                    {expandedRows[index] && trObject?.expandable?.length !== 0 && (
                                        <div className="table-tr" style={{"padding": "15px"}}>
                                            <b className={'text ' + fontSize}
                                                  dangerouslySetInnerHTML={{__html: trObject.expandable}}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )
                        })
                    }

                    {
                        tr.length == 0 && (
                            <div className={"table-tr table-tr-empty "}>
                                <p className={'text text-s12'} style={{color:"#718096"}}>
                                    нет записей
                                </p>
                            </div>
                        )
                    }
                </div>
            </div>

            {
                paginationList.length > 0 && (
                    <div className="table__footer">

                        <div className="pagination">
                            {
                                paginationList.map((el, index) => {
                                    if ((index) <= 2) {
                                        return (
                                            <div onClick={() => {
                                                setPage(index);
                                            }} className={'pagination-el text text-s14 ' + (page == index && ' active ')}>
                                                {index + 1}
                                            </div>
                                        );
                                    }

                                    if ((index) >= paginationList.length - 3) {
                                        return (
                                            <div onClick={() => {
                                                setPage(index);
                                            }} className={'pagination-el text text-s14 ' + (page == index && ' active ')}>
                                                {index + 1}
                                            </div>
                                        );
                                    }

                                    if ((index) >= page - 1 && (index) <= page + 1) {
                                        return (
                                            <div onClick={() => {
                                                setPage(index);
                                            }} className={'pagination-el text text-s14 ' + (page == index && ' active ')}>
                                                {index + 1}
                                            </div>
                                        );
                                    }

                                    return (
                                        <div className={'pagination-empty'}>...</div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );

}

export default Table;
