import './scss/index.scss';
import Calendar from "../../../../components/Calendar";

// @ts-ignore
import plus from "../../../registry/assets/icon/plus.svg";
// @ts-ignore
import down from "./assets/icon/down.svg";
// @ts-ignore
import tasks_container from "./assets/img/Tasks_container.svg";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {

    return (
        <div className={'meetings'}>

            <div className="meetings-left">
                <div className="meetings-head">
                    <p className={'text text-s16'}>
                        <b>Октябрь, 14-20</b>
                    </p>
                    <div className="btn-head-el btn-head-el-mini">
                        <div className="btn btn-data text text-s14"> Октябрь <img src={down}/></div>
                        <div className="btn btn-create text text-s14"><img src={plus}/> Создать новый</div>
                    </div>
                </div>
                <p style={{margin: "6.1rem 0"}}></p>
                <img src={tasks_container} style={{width: "100%"}}/>
            </div>
            <div className="meetings-calendar">
                <Calendar/>
            </div>
        </div>
    );

}

export default Empty;