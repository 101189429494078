import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";


import contentSvg1 from "./assets/img/Content.svg";
import BoxNav from "../../components/BoxNav";

import svg1 from "./assets/icon/1.svg";
import svg2 from "./assets/icon/2.svg";
import svg3 from "./assets/icon/3.svg";
import svg4 from "./assets/icon/4.svg";
export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(0);


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Онбординг
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Обучение по онбордингу
                </p>

            </div>
        )}>

            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            Обучение по онбордингу
                        </b>
                    </div>
                </div>
            </div>

            <div className="workplace-space" >
                <BoxNav step={step} onStep={(st) => {
                    setStep(st);
                }} content={[
                    {
                        name: '1 день',
                        icon: svg1,
                        content: (<>
                            <img src={contentSvg1} style={{width: "100%"}}/>
                        </>)
                    },
                    {
                        name: '1 неделя',
                        icon: svg2,
                        content: (<>
                            <img src={contentSvg1} style={{width: "100%"}}/>
                        </>)
                    },
                    {
                        name: '1 месяц',
                        icon: svg3,
                        content: (<>
                            <img src={contentSvg1} style={{width: "100%"}}/>
                        </>)
                    },
                    {
                        name: '3 месяц',
                        icon: svg4,
                        content: (<>
                            <img src={contentSvg1} style={{width: "100%"}}/>
                        </>)
                    }
                ]}/>
            </div>
        </Layout>
    );


}