import './scss/index.scss';

// @ts-ignore
import InputMini from "../../../../components/InputMini";
import TableCustom from "../../../../components/TableCustom";
// @ts-ignore
import time_line_visit from "./assets/img/col.svg";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'work-time'}>
            <div className="filter-box filter-box-p0 filter-box-register">

                <div className="filter-box__inputs filter-box__inputs-time">
                    <div className="work-time-input work-time-input-i11">
                        <InputMini name={'Период с'} placeholder={'5-и дневка'}/>
                    </div>
                    <p style={{margin: "0 1rem"}}></p>
                    <div className="work-time-input work-time-input-i22">
                        <InputMini name={'по'} placeholder={'Выбрать месяц'}/>
                    </div>

                    <div className="btn-head-el btn-head-el-line" style={{marginLeft: "auto"}}>
                        <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                        <div className="btn btn-create text text-s14"> Применить</div>
                    </div>
                </div>


            </div>
            <p style={{padding: "4rem 0"}}></p>
            <TableCustom
                whType={'rem'}
                th={{
                    1: {
                        name: 'ID',
                        w: 12,
                        option: null
                    },
                    2: {
                        name: 'Сотрудник',
                        w: 15,
                        option: null
                    },
                    3: {
                        name: 'Опоздание',
                        w: 15,
                        option: null
                    },
                    4: {
                        name: 'Время опоздания',
                        w: 15,
                        option: null
                    },
                    5: {
                        name: 'Значение',
                        w: 15,
                        option: null
                    },
                }}
                tr={
                    [
                        {
                            1: "1",
                            2: "Жанатова Шынар",
                            3: "Нет",
                            4: "0ч 0мин",
                            5: "9:00",
                        }, {
                        1: "2",
                        2: "Ниязов Еркебулан",
                        3: "Да",
                        4: "0ч 40мин",
                        5: "9:40",
                    }, {
                        1: "3",
                        2: "Алиева Сауле",
                        3: "Да",
                        4: "1ч 19мин",
                        5: "10:19",
                    }, {
                        1: "4",
                        2: "Калмуратова Камшат",
                        3: "Нет",
                        4: "0ч 0мин",
                        5: "9:00",
                    }, {
                        1: "5",
                        2: "Жанатова Шынар",
                        3: "Да",
                        4: "2ч 13мин",
                        5: "11:13",
                    }
                    ]
                }
            />

            <p style={{padding: "4rem 0"}}></p>
            <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                <img style={{width: "71.6rem", margin: "auto"}} src={time_line_visit}/>
            </div>

        </div>
    );

}

export default Empty;