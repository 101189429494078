import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";
import BoxNav from "../../components/BoxNav";
import Org from "./assets/img/org.svg";


import create from "./assets/icon/create.svg";
import plus from "./assets/icon/plus.svg";
import InputMini from "../../components/InputMini";
import TableCustom from "../../components/TableCustom";
import {getAllOrder, getUserInfo} from "../../services";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(2);

    const [order, setAllOrder] = useState([]);


    async function getAllInfo() {
        const userInfo = (await getAllOrder(state_in.token)).data;
        setAllOrder(userInfo);
    }

    useEffect(() => {

        getAllInfo().then();

    }, []);
    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Настройки
                </p>
            </div>

        )}>


            <p style={{margin: "3rem 0"}}></p>
            <TableCustom
                whType={'rem'}
                th={{

                    id: {
                        name: 'ID',
                        w: 12,
                        option: null
                    },
                    name: {
                        name: 'Имя',
                        w: 17.4,
                        option: null
                    },
                    email: {
                        name: 'Почта',
                        w: 17.4,
                        option: null
                    },
                    phone: {
                        name: 'Телефон',
                        w: 17.4,
                        option: null
                    },
                    created_at: {
                        name: 'Создана',
                        w: 17.4,
                        option: null
                    },

                }}
                tr={
                    order
                }
            />


        </Layout>
    );


}