import './scss/index.scss';
import Drop from "../Drop";
import TableCustom from "../../../../components/TableCustom";
import InputMini from "../../../../components/InputMini";


interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'task-personal'}>
            <Drop name={'Курсы'} drop={() => {
                return (
                    <div className={'task-personal-table'}>
                        <div className="filter-box filter-box-p0 filter-box-p0 filter-box-register">

                            <div className="filter-box__inputs filter-box__inputs-mini">
                                <InputMini name={'Курс'} placeholder={'Название курса '}/>
                                <InputMini name={'Тип задачи'} placeholder={'Выберите тип'}/>
                                <InputMini name={'Куратор'} placeholder={'Введите ФИО'}/>
                                <InputMini name={'Приоритет'} placeholder={'Выберите приоритет '}/>
                                <InputMini name={'Дедлайн'} placeholder={'Выберите дедлайн '}/>
                                <InputMini name={'По статусу'} placeholder={'Выберите статус'}/>
                            </div>
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                                <div className="btn btn-create text text-s14"> Применить</div>
                            </div>

                        </div>
                        <p style={{margin: "2.4rem 0"}}></p>
                        <TableCustom
                            whType={'rem'}
                            th={{
                                1: {
                                    name: 'ID',
                                    w: 12,
                                    option: null
                                },
                                2: {
                                    name: 'Тема задачи',
                                    w: 15,
                                    option: null
                                },
                                3: {
                                    name: 'Тип задачи',
                                    w: 15,
                                    option: null
                                },
                                4: {
                                    name: 'Приоритет',
                                    w: 15,
                                    option: null
                                },
                                5: {
                                    name: 'Дедлайн',
                                    w: 15,
                                    option: null
                                },
                                6: {
                                    name: 'Спикер курса',
                                    w: 15,
                                    option: null
                                },
                                7: {
                                    name: 'Результаты',
                                    w: 15,
                                    option: null
                                },
                                8: {
                                    name: 'Комментарий',
                                    w: 15,
                                    option: null
                                },
                                9: {
                                    name: 'Статус',
                                    w: 20,
                                    option: ((row) => {
                                        return (
                                            <div className="task-option">
                                                <a className={'console-post-list-create console-post-list-create-bly text text-s12'}> {row[9]} </a>
                                            </div>
                                        )
                                    })
                                },
                            }}
                            tr={
                                [
                                    {
                                        1: "11",
                                        2: "Тема",
                                        3: "Подбор персонала",
                                        4: "Срочно",
                                        5: "12.10.2024",
                                        6: "Бабахан Фатима",
                                        7: "80",
                                        8: "Написано коммент",
                                        9: "В работе",
                                    }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Онбординг",
                                    4: "Не срочно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "80",
                                    8: "Написано коммент",
                                    9: "Выполнено",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Онбординг",
                                    4: "Подождет",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "80",
                                    8: "Написано коммент",
                                    9: "Не выполнено",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Кадровое адм.",
                                    4: "Ждет неспешно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "80",
                                    8: "Написано коммент",
                                    9: "Невозможно выполнить",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Мотивация",
                                    4: "Не срочно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "80",
                                    8: "Написано коммент",
                                    9: "Выполнено",
                                }
                                ]
                            }
                        />
                    </div>
                );
            }}/>

            <p style={{margin: "10rem 0"}}></p>
            <Drop name={'Результаты'} drop={() => {
                return (
                    <div className={'task-personal-table'}>
                        <div className="filter-box filter-box-p0 filter-box-register">
                            <div className="filter-box__inputs filter-box__inputs-mini">
                                <InputMini name={'Курс'} placeholder={'Название курса '}/>
                                <InputMini name={'Тип задачи'} placeholder={'Выберите тип'}/>
                                <InputMini name={'Куратор'} placeholder={'Введите ФИО'}/>
                                <InputMini name={'Приоритет'} placeholder={'Выберите приоритет '}/>
                                <InputMini name={'Дедлайн'} placeholder={'Выберите дедлайн '}/>
                                <InputMini name={'По статусу'} placeholder={'Выберите статус'}/>
                            </div>
                            <div className="btn-head-el">
                                <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                                <div className="btn btn-create text text-s14"> Применить</div>
                            </div>

                        </div>
                        <p style={{margin: "2.4rem 0"}}></p>

                        <TableCustom
                            whType={'rem'}
                            th={{
                                1: {
                                    name: 'ID',
                                    w: 12,
                                    option: null
                                },
                                2: {
                                    name: 'Тема курса',
                                    w: 15,
                                    option: null
                                },
                                3: {
                                    name: 'Тип задачи',
                                    w: 15,
                                    option: null
                                },
                                6: {
                                    name: 'Спикер курса',
                                    w: 15,
                                    option: null
                                },
                                7: {
                                    name: 'Результаты',
                                    w: 15,
                                    option: null
                                },
                                11: {
                                    name: 'Попытки пересдачи',
                                    w: 15,
                                    option: null
                                },
                                12: {
                                    name: 'Период',
                                    w: 15,
                                    option: null
                                },
                            }}
                            tr={
                                [
                                    {
                                        1: "11",
                                        2: "Тема",
                                        3: "Подбор персонала",
                                        4: "Срочно",
                                        5: "12.10.2024",
                                        6: "Бабахан Фатима",
                                        7: "41 55 62 72 95",
                                        8: "Написано коммент",
                                        9: "В работе",
                                        11: "5",
                                        12: "01.10.2024 - 30.12.2024",
                                    }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Онбординг",
                                    4: "Не срочно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "41 55 62 72 95",
                                    8: "Написано коммент",
                                    9: "В работе",
                                    11: "5",
                                    12: "01.10.2024 - 30.12.2024",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Онбординг",
                                    4: "Подождет",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "41 55 62 72 95",
                                    8: "Написано коммент",
                                    9: "В работе",
                                    11: "5",
                                    12: "01.10.2024 - 30.12.2024",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Кадровое адм.",
                                    4: "Ждет неспешно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "41 55 62 72 95",
                                    8: "Написано коммент",
                                    9: "В работе",
                                    11: "5",
                                    12: "01.10.2024 - 30.12.2024",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Мотивация",
                                    4: "Не срочно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "41 55 62 72 95",
                                    8: "Написано коммент",
                                    9: "В работе",
                                    11: "5",
                                    12: "01.10.2024 - 30.12.2024",
                                }
                                ]
                            }
                        />
                    </div>
                );
            }}/>


            <p style={{margin: "10rem 0"}}></p>
            <Drop name={'Анонс курсов'} drop={() => {
                return (
                    <div className={'task-personal-table'}>

                        <TableCustom
                            whType={'rem'}
                            th={{
                                1: {
                                    name: 'ID',
                                    w: 12,
                                    option: null
                                },
                                2: {
                                    name: 'Тема курса',
                                    w: 15,
                                    option: null
                                },
                                3: {
                                    name: 'Тип задачи',
                                    w: 15,
                                    option: null
                                },
                                6: {
                                    name: 'Спикер курса',
                                    w: 15,
                                    option: null
                                },
                                13: {
                                    name: 'Спикер курса',
                                    w: 15,
                                    option: null
                                },
                                14: {
                                    name: 'Закрытие курса',
                                    w: 15,
                                    option: null
                                },
                            }}
                            tr={
                                [
                                    {
                                        1: "11",
                                        2: "Тема",
                                        3: "Подбор персонала",
                                        4: "Срочно",
                                        5: "12.10.2024",
                                        6: "Бабахан Фатима",
                                        7: "41 55 62 72 95",
                                        8: "Написано коммент",
                                        9: "В работе",
                                        11: "5",
                                        12: "01.10.2024 - 30.12.2024",
                                        13: "01.10.2024",
                                        14: "30.12.2024",
                                    }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Онбординг",
                                    4: "Не срочно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "41 55 62 72 95",
                                    8: "Написано коммент",
                                    9: "В работе",
                                    11: "5",
                                    12: "01.10.2024 - 30.12.2024",
                                    13: "01.10.2024",
                                    14: "30.12.2024",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Онбординг",
                                    4: "Подождет",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "41 55 62 72 95",
                                    8: "Написано коммент",
                                    9: "В работе",
                                    11: "5",
                                    12: "01.10.2024 - 30.12.2024",
                                    13: "01.10.2024",
                                    14: "30.12.2024",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Кадровое адм.",
                                    4: "Ждет неспешно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "41 55 62 72 95",
                                    8: "Написано коммент",
                                    9: "В работе",
                                    11: "5",
                                    12: "01.10.2024 - 30.12.2024",
                                    13: "01.10.2024",
                                    14: "30.12.2024",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Мотивация",
                                    4: "Не срочно",
                                    5: "12.10.2024",
                                    6: "Бабахан Фатима",
                                    7: "41 55 62 72 95",
                                    8: "Написано коммент",
                                    9: "В работе",
                                    11: "5",
                                    12: "01.10.2024 - 30.12.2024",
                                    13: "01.10.2024",
                                    14: "30.12.2024",
                                }
                                ]
                            }
                        />
                    </div>
                );
            }}/>
        </div>
    );

}

export default Empty;