import React, {useEffect, useState} from 'react';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import {useSelector} from "react-redux";
import LangSlices from "../../redux/slices/Lang";
import StateSlices from "../../redux/slices/State";

import {NavLink} from 'react-router-dom'
import State from "../../redux/actions/StateActions";
import Btn from "../Btn";
import logoImg from "../../assets/images/logo.svg";
import barImg from "./assets/icon/bar.svg";
import bankImg from "./assets/icon/bank.svg";
import invoiceImg from "./assets/icon/invoice.svg";
import aktImg from "./assets/icon/akt.svg";
import arrowImg from "./assets/icon/arrow-sort.svg";
import bookImg from "./assets/icon/open-book.svg";

import helpImg from "./assets/icon/help.svg";
import settingImg from "./assets/icon/setting.svg";


import chevronImg from "./assets/icon/chevron-down.svg";

import user_img from "../../views/personal/assets/img/user.png";
import upImg from "./assets/icon/up-arrow.svg";
import downImg from "./assets/icon/down-arrow.svg";

import moneyImg from "./assets/icon/money.svg";
import menuMain from "./assets/icon/menu_main.svg";
import kadrovoe from "./assets/icon/kadrovoe.svg";
import controls from "./assets/icon/controls.svg";
import workLImg from "./assets/icon/work.svg";


import onSvg1 from "./assets/icon/1.svg";
import onSvg2 from "./assets/icon/2.svg";
import onSvg3 from "./assets/icon/3.svg";
import onSvg4 from "./assets/icon/4.svg";
import onSvg5 from "./assets/icon/5.svg";
import onSvg6 from "./assets/icon/6.svg";
import onSvg7 from "./assets/icon/7.svg";

import notificationsImg from "./assets/icon/notifications.svg";


const {REACT_APP_API_PATH} = process.env;

const Layout = (({logo, children, className, headTitle = ''}) => {

    const lang = useSelector(LangSlices);
    const state_in = useSelector(StateSlices);

    const [menuOpen, setMenuOpen] = useState(false);
    const [openSetting, setOpenSetting] = useState(false);

    const [openBox, setOpenBox] = useState(false);

    const [page, setPage] = useState(null);

// Сохранение состояния страницы в localStorage
    useEffect(() => {
        if (page !== null) {
            localStorage.setItem('activePage', page);
        }
    }, [page]);

// Загрузка состояния страницы из localStorage
    useEffect(() => {
        const savedPage = localStorage.getItem('activePage');
        if (savedPage !== null) {
            setPage(parseInt(savedPage, 10));
        }
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    useEffect(() => {


    }, []);


    let message_error = [].reverse();


    let urlLink = {
        'Order': 'Все заказы',
        'Tilda': 'Все заказы с сайта',
        'Accounting': 'Учет посещения',
        'AccountingAdmin': 'Всё посещение сотрудников',
        'Product': 'Все оборудование',
        'Sklad': 'Вся аналитика по складу',
        'ProductMenu': 'Все Меню',
        'Personal': 'Весь персонал',
        'Pack': 'Все готовые чек-листы',
        'ProductDown': 'Все списанные товаров групами',
        'Broken': 'Все утеря',
        'Inventory': 'Вся инвентаризация',
        'Role': 'Все роли',
        'Group': 'Все разделы',
        'Repair': 'Вся ремонт доска',
        'ProductTopDown': 'Вся аналитика списания',
        'SkladAn': 'Вся аналитика по складу',
        'ProductTop': 'Вся аналитика',
        'OrderDown': 'Весь просроченый демонтаж',
        'Prihod': 'Весь приход',
        'RepairBoard': 'Вся Ремонтная доска',
        'User': 'Все пользователи'
    };


    let urlPathSplit = location.pathname;


    return (<>
            <div>
                <div className={'main-box'}>

                    <div className="header-profil">

                        <div className="site-bar__head">
                            <b className={'login-form-head-logo text text-head text-color-def text-s24'}>HR
                                Basic </b>

                            {
                                headTitle
                            }
                        </div>

                        <div className={'user-name-info'}>
                            <img src={notificationsImg} style={{width: "4.2rem", marginRight: "1.6rem"}}/>
                            <div className={'btn-head btn-head-profile text text-s16 '}>
                                <div style={{backgroundImage: "url(" + user_img + ")"}} className={'profile-img'}></div>
                                <div className={'work-name'}>
                                    <p className={'text text-s18'}>
                                        <b>Жанатова Шынар</b>
                                    </p>
                                    <p className={'text text-s12'}
                                       style={{color: "#A0AEC0", marginTop: '0.5rem'}}>
                                        HR-менеджер
                                    </p>
                                </div>
                                <img src={chevronImg} style={{width: "1.6rem"}}/>
                            </div>
                        </div>


                    </div>
                    <NotificationContainer/>
                    <div className="main-box-container">
                        <div className={'site-bar ' + (menuOpen && 'active')}>


                            <div className="site-bar__menu">
                                {/*['Списанные товары', '/ProductDown/browse', 'ProductDown'],*/}



                                {[[
                                    'Главное меню', '/dashboard', menuMain],
                                    ['Управление', '', controls, [['О компании', '/company', controls]]],
                                    ['Кадровое администрирование', '/', kadrovoe, [
                                        ['Личные карточки сотрудников', '/personal', controls],
                                        ['Реестр документов', '/registry', controls],
                                        ['Учет рабочего времени', '/time', controls],
                                        ['Отсутствия сотрудников', '/employee_absences', controls],
                                    ]],
                                    ['Подбор персонала', '', onSvg1,[
                                        ['График встреч', '/personals', controls],
                                        ['Банк резюме', '/bank', controls],
                                        ['Программа найма', '/naim', controls],
                                        ['Управление подбором', '/control', controls],
                                    ]],
                                    ['Онбординг', '', onSvg2, [
                                        ['Организация рабочего места', '/workplace', controls],
                                        ['План онбординга', '/plan_onboarding', controls],
                                        ['Обучение по онбордингу', '/tutorial', controls],
                                    ]],
                                    ['Мотивация', 'des', onSvg3],
                                    ['Обучение', '', onSvg4,[
                                        ['Материалы', '/training', controls],
                                        ['ИПР', '/ipr', controls],
                                        ['Тестирование', 'des', controls],
                                    ]],
                                    ['Оценка персонала', 'des', onSvg5],
                                    ['Корпоративная культура', 'des', onSvg6],
                                    ['Настройки', '/settings', onSvg7],
                                ].map((text, index) => {

                                    if(text[1]=='des'){
                                        return (
                                            <div
                                                onClick={()=>{
                                                    alert('Нет доступа')
                                                }}
                                                className={'site-bat-link ' }>
                                                <div className="site-bat-link-icon"
                                                     style={{maskImage: "url('" + text[2] + "')"}}></div>
                                                <span className={'text text-s16'}>{text[0]}</span>
                                            </div>
                                        )
                                    }
                                    if (text.length >= 4) {
                                        return (
                                            <div>
                                                <div
                                                    onClick={() => setPage(index)}
                                                    className={'site-bat-link ' + (urlPathSplit == text[1] && 'active')}>
                                                    <div class="site-bat-link-icon"
                                                         style={{maskImage: "url('" + text[2] + "')"}}></div>
                                                    <span className={'text text-s16'}>{text[0]}</span>
                                                </div>


                                                {
                                                    index == page && (
                                                        <div className={'children-box-fix'}>
                                                            {
                                                                text[3].map((childreaMap, childreaMapIndex) => {

                                                                    if(childreaMap[1]=='des'){
                                                                        return (
                                                                            <div
                                                                                onClick={()=>{
                                                                                    alert('Нет доступа')
                                                                                }}
                                                                                className={'site-bat-link ' }>
                                                                                <span className={'text text-s16'}>{childreaMap[0]}</span>
                                                                            </div>
                                                                        )
                                                                    }

                                                                    return (<NavLink
                                                                        key={childreaMapIndex} to={childreaMap[1]}
                                                                        className={'site-bat-link ' + (urlPathSplit == childreaMap[1] && 'active')}>

                                                                        <span
                                                                            className={'text text-s16'}>{childreaMap[0]}</span>
                                                                    </NavLink>)
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }

                                    return (
                                        <NavLink
                                            key={index} to={text[1]}
                                            className={'site-bat-link ' + (urlPathSplit == text[1] && 'active')}>
                                            <div class="site-bat-link-icon"
                                                 style={{maskImage: "url('" + text[2] + "')"}}></div>
                                            <span className={'text text-s16'}>{text[0]}</span>
                                        </NavLink>
                                    )
                                })
                                }


                            </div>


                        </div>
                        <main style={{position: 'relative'}}>
                            <div className="nav-head-user">
                                <div onClick={() => {

                                    setOpenSetting(!openSetting);
                                }} className="nav-head-user-src">
                                    <p className={'text text-s16'}>
                                        {state_in?.authUser?.name}
                                    </p>

                                    {openSetting && (<>
                                        <div className="open-setting-user">
                                            <Btn type={'submit'} onClick={() => {
                                                State({
                                                    token_: false, auth: false, authUser: false,
                                                })
                                            }} className={'btn-def-table text text-s16'} label={'выйти'}/>

                                        </div>
                                    </>)}
                                </div>
                            </div>
                            <div className={'ci-while-bk'}>
                                {children}
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
});
export default Layout;
