import './scss/index.scss';
import InputMini from "../../../../components/InputMini";
import TableCustom from "../../../../components/TableCustom";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'employees-box'}>
            <div className="filter-box filter-box-p0 filter-box-register">

                <div className="filter-box__inputs">
                    <InputMini name={'Отдел'} placeholder={'Выберите тип документа '}/>
                    <InputMini name={'ФИО сотрудника'} placeholder={'ФИО'}/>
                    <InputMini name={'По месяцу рождения'} placeholder={'Выберите статус'}/>
                    <InputMini name={'Дата приема'} placeholder={'Выберите дату '}/>
                </div>
                <div className="btn-head-el">
                    <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                    <div className="btn btn-create text text-s14"> Применить</div>
                </div>

            </div>


            <p style={{margin: "3rem 0"}}></p>
            <TableCustom
                whType={'rem'}
                th={{
                    1: {
                        name: 'ID',
                        w: 12,
                        option: null
                    },
                    2: {
                        name: 'Отдел',
                        w: 17.4,
                        option: null
                    },
                    3: {
                        name: 'ФИО сотрудника',
                        w: 17.4,
                        option: null
                    },
                    4: {
                        name: 'Должность',
                        w: 17.4,
                        option: null
                    },
                    5: {
                        name: 'Дата приема',
                        w: 17.4,
                        option: null
                    },
                    6: {
                        name: 'Контакты',
                        w: 17.4,
                        option: null
                    },
                    7: {
                        name: 'Почта',
                        w: 17.4,
                        option: null
                    },
                    8: {
                        name: 'День рождения ',
                        w: 17.4,
                        option: null
                    },

                }}
                tr={
                    [
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        },
                        {
                            1: "11",
                            2: "Маркетинг",
                            3: "Бабахан Фатима Абытханкызы",
                            4: "Дизайнер",
                            5: "12.12.2024",
                            6: "+7 777 123 45 67",
                            7: "example@gmail.com",
                            8: "01.01.1999",
                        }
                    ]
                }
            />

        </div>
    );

}

export default Empty;