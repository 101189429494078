import './scss/index.scss';
// @ts-ignore
import documentColor from "../Drop/assets/icon/document-color.svg";
import Drop from "../Drop";
import InputMini from "../../../../components/InputMini";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <>
            <div className="search-personal-filter">
                <InputMini name={''} search={true} placeholder={'Ваш запрос'}/>
                <div className="btn btn-create text text-s14">Поиск</div>
            </div>
            <div className={'document-box-page'}>


                <Drop name={'Договоры'} iconDocument={true} edit={false} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="upload-file list-fix-border-dex">

                                <p className={'text text-s16'}>
                                    Договор о конфиденциальности информации Калмуратова Камшат
                                </p>
                                <p className={'border-line-list'}></p>
                                <p className={'text text-s16'}>
                                    20 стр.
                                </p>
                            </div>

                            <div className="upload-file list-fix-border-dex">

                                <p className={'text text-s16'}>
                                    Договор о материальной ответственности Калмуратова Камшат
                                </p>
                                <p className={'border-line-list'}></p>
                                <p className={'text text-s16'}>
                                    8 стр.
                                </p>
                            </div>


                            <div className="upload-file list-fix-border-dex">

                                <p className={'text text-s16'}>
                                    Договор о неконкуренции Калмуратова Камшат
                                </p>
                                <p className={'border-line-list'}></p>
                                <p className={'text text-s16'}>
                                    8 стр.
                                </p>
                            </div>

                            <div className="upload-file list-fix-border-dex">

                                <p className={'text text-s16'}>
                                    Договор о конфиденциальности информации Калмуратова Камшат
                                </p>
                                <p className={'border-line-list'}></p>
                                <p className={'text text-s16'}>
                                    20 стр.
                                </p>
                            </div>


                            <div className="upload-file list-fix-border-dex">

                                <p className={'text text-s16'}>
                                    Договор о конфиденциальности информации Калмуратова Камшат

                                </p>
                                <p className={'border-line-list'}></p>
                                <p className={'text text-s16'}>
                                    13 стр.
                                </p>
                            </div>

                            <div className="upload-file list-fix-border-dex">

                                <p className={'text text-s16'}>
                                    Договор ГПХ Калмуратова Камшат
                                </p>
                                <p className={'border-line-list'}></p>
                                <p className={'text text-s16'}>
                                    17 стр.
                                </p>
                            </div>

                            <div className="upload-file list-fix-border-dex">

                                <p className={'text text-s16'}>
                                    Коллективный договор Калмуратова Камшат
                                </p>
                                <p className={'border-line-list'}></p>
                                <p className={'text text-s16'}>
                                    15 стр.
                                </p>
                            </div>
                        </div>
                    )
                }}/>

                <Drop name={'Документы'} edit={false} drop={() => {
                    return (
                        <div className={'drop-list'}>
                            <div className="upload-file">
                                <div className="upload-file-icon"
                                     style={{backgroundImage: "url(" + documentColor + ")"}}></div>
                                <div className="upload-file-name">
                                    <p className={'text text-s18'}>
                                        Договора
                                    </p>
                                    <p style={{margin: "0.25rem 0"}}></p>
                                    <p className={'text text-gray text-s14'}>
                                        20 FIles
                                    </p>
                                </div>
                                <div className="upload-info">
                                    <p className={'text text-s14'}>
                                        15.0 Gb
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }}/>
            </div>
        </>
    );

}

export default Empty;