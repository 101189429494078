import './scss/index.scss';

interface Props {
    name: string;
}


interface Props {
    // @ts-ignore
    content: object[object];
    step?: number;
    imageType?: boolean;
    first?: boolean;
    onStep?: Function;
}

const Empty: React.FC<Props> = ({
                                    content = [], step = 0, imageType = false, first = false, onStep = (() => {
    })
                                }) => {


    const head = content;

    return (
        <div className={'box-nav'}>
            <div className={"box-nav-head "+(first && 'box-nav-head-first')}>

                {
                    head.map((headMap, index) => {
                        if (!headMap?.name) {
                            return "";
                        }
                        return (
                            <div onClick={() => {
                                if (onStep) {
                                    onStep(index);
                                }
                            }} className={'box-nav-th text text-s16 ' + (step == index && "active")}
                                 style={{width: (100 / head.length) + "%"}}>

                                <div className="box-nav-th-body">

                                    {
                                        !imageType && (<div className="box-nav-th-icon"
                                                            style={{maskImage: "url('" + headMap.icon + "')"}}></div>)
                                    }
                                    {
                                        imageType && (<div className="box-nav-th-icon box-nav-th-icon-img"
                                                           style={{backgroundImage: "url('" + headMap.icon + "')"}}></div>)
                                    }
                                    {headMap.name}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
            <div className="box-nav-body">
                {
                    head.map((headMap, index) => {
                        if (step == index && headMap?.content) {
                            return (
                                <div className={'text text-s16'}>
                                    {headMap?.content}
                                </div>
                            );
                        }
                        return (
                            <div className={'text text-s16'}>

                            </div>
                        );
                    })
                }
            </div>
        </div>
    );

}

export default Empty;