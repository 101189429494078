import './scss/index.scss';
import Drop from "../Drop";
import TableCustom from "../../../../components/TableCustom";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'task-personal'}>
            <Drop name={'Мои задачи'} drop={() => {
                return (
                    <div className={'task-personal-table'}>
                        <TableCustom
                            whType={'rem'}
                            th={{
                                1: {
                                    name: 'ID',
                                    w: 12,
                                    option: null
                                },
                                2: {
                                    name: 'Тема задачи',
                                    w: 15,
                                    option: null
                                },
                                3: {
                                    name: 'Тип задачи',
                                    w: 15,
                                    option: null
                                },
                                4: {
                                    name: 'Приоритет',
                                    w: 15,
                                    option: null
                                },
                                5: {
                                    name: 'Дедлайн',
                                    w: 15,
                                    option: null
                                },
                                6: {
                                    name: 'Дата создания',
                                    w: 15,
                                    option: null
                                },
                                7: {
                                    name: 'Вложения',
                                    w: 15,
                                    option: null
                                },
                                8: {
                                    name: 'Комментарий',
                                    w: 15,
                                    option: null
                                },
                                9: {
                                    name: 'Статус',
                                    w: 20,
                                    option: ((row) => {
                                        return (
                                            <div className="task-option">
                                                <a className={'console-post-list-create console-post-list-create-bly text text-s12'}> {row[9]} </a>
                                            </div>
                                        )
                                    })
                                },
                            }}
                            tr={
                                [
                                    {
                                        1: "11",
                                        2: "Тема",
                                        3: "Подбор персонала",
                                        4: "Срочно",
                                        5: "12.10.2024",
                                        6: "01.10.2024",
                                        7: "image.png",
                                        8: "Написано коммент",
                                        9: "В работе",
                                    },{
                                    1: "11",
                                    2: "Тема",
                                    3: "Онбординг",
                                    4: "Не срочно",
                                    5: "12.10.2024",
                                    6: "01.10.2024",
                                    7: "image.png",
                                    8: "Написано коммент",
                                    9: "Выполнено",
                                },{
                                    1: "11",
                                    2: "Тема",
                                    3: "Онбординг",
                                    4: "Подождет",
                                    5: "12.10.2024",
                                    6: "01.10.2024",
                                    7: "image.png",
                                    8: "Написано коммент",
                                    9: "Не выполнено",
                                },{
                                    1: "11",
                                    2: "Тема",
                                    3: "Кадровое адм.",
                                    4: "Ждет неспешно",
                                    5: "12.10.2024",
                                    6: "01.10.2024",
                                    7: "image.png",
                                    8: "Написано коммент",
                                    9: "Невозможно выполнить",
                                },{
                                    1: "11",
                                    2: "Тема",
                                    3: "Мотивация",
                                    4: "Не срочно",
                                    5: "12.10.2024",
                                    6: "01.10.2024",
                                    7: "image.png",
                                    8: "Написано коммент",
                                    9: "Выполнено",
                                }
                                ]
                            }
                        />
                    </div>
                );
            }}/>

            <p style={{margin: "10rem 0"}}></p>

            <Drop name={'Общие задачи'} drop={() => {
                return (
                    <div className={'task-personal-table'}>
                        <TableCustom
                            whType={'rem'}
                            th={{

                                1: {
                                    name: 'ID',
                                    w: 12,
                                    option: null
                                },
                                2: {
                                    name: 'Тема задачи',
                                    w: 15,
                                    option: null
                                },
                                3: {
                                    name: 'Тип задачи',
                                    w: 15,
                                    option: null
                                },
                                4: {
                                    name: 'Приоритет',
                                    w: 15,
                                    option: null
                                },
                                5: {
                                    name: 'Дедлайн',
                                    w: 15,
                                    option: null
                                },
                                6: {
                                    name: 'Дата создания',
                                    w: 15,
                                    option: null
                                },
                                7: {
                                    name: 'Вложения',
                                    w: 15,
                                    option: null
                                },
                                8: {
                                    name: 'Комментарий',
                                    w: 15,
                                    option: null
                                },
                                9: {
                                    name: 'Статус',
                                    w: 20,
                                    option: ((row) => {
                                        return (
                                            <div className="task-option">
                                                <a className={'console-post-list-create console-post-list-create-bly text text-s12'}> {row[9]} </a>
                                            </div>
                                        )
                                    })
                                },
                            }}
                            tr={
                                [
                                    {
                                        1: "11",
                                        2: "Тема",
                                        3: "Бабахан Фатима Абытханкызы",
                                        4: "Бабахан Фатима Абытханкызы",
                                        5: "12.10.2024",
                                        6: "01.10.2024",
                                        7: "image.png",
                                        8: "Написано коммент",
                                        9: "Выполнено",
                                    }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Бабахан Фатима Абытханкызы",
                                    4: "Бабахан Фатима Абытханкызы",
                                    5: "12.10.2024",
                                    6: "01.10.2024",
                                    7: "image.png",
                                    8: "Написано коммент",
                                    9: "Выполнено",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Бабахан Фатима Абытханкызы",
                                    4: "Бабахан Фатима Абытханкызы",
                                    5: "12.10.2024",
                                    6: "01.10.2024",
                                    7: "image.png",
                                    8: "Написано коммент",
                                    9: "Выполнено",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Бабахан Фатима Абытханкызы",
                                    4: "Бабахан Фатима Абытханкызы",
                                    5: "12.10.2024",
                                    6: "01.10.2024",
                                    7: "image.png",
                                    8: "Написано коммент",
                                    9: "Выполнено",
                                }, {
                                    1: "11",
                                    2: "Тема",
                                    3: "Бабахан Фатима Абытханкызы",
                                    4: "Бабахан Фатима Абытханкызы",
                                    5: "12.10.2024",
                                    6: "01.10.2024",
                                    7: "image.png",
                                    8: "Написано коммент",
                                    9: "Выполнено",
                                }
                                ]
                            }
                        />
                    </div>
                );
            }}/>
        </div>
    );

}

export default Empty;