import './scss/index.scss';

// @ts-ignore
import table_info_img from "./assets/img/table_info.svg";
import Drop from "../../../personal/components/Drop";
import InputMini from "../../../../components/InputMini";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <div className={'work-time'}>
            <div className="filter-box filter-box-p0 filter-box-register">

                <div className="filter-box__inputs filter-box__inputs-time">
                    <div className="work-time-input work-time-input-i1">
                        <InputMini name={'Название'} placeholder={'5-и дневка'}/>
                    </div>
                    <div className="work-time-input work-time-input-i2">
                        <InputMini name={'Месяц'} placeholder={'Выбрать месяц'}/>
                    </div>
                    <div className="work-time-input work-time-input-i3">
                        <InputMini name={'Год'} placeholder={'Выбрать год '}/>
                    </div>
                </div>
                <div className="btn-head-el">
                    <div className="btn btn-filter text text-s14">Сбросить фильтры</div>
                    <div className="btn btn-create text text-s14"> Применить</div>
                </div>

            </div>
            <Drop name={'1 неделя'} edit={false} drop={() => {
                return (
                    <div className={'work-time-table'}>
                        <img style={{width: "100%"}} src={table_info_img}/>
                    </div>
                )
            }}
            />
            <p style={{padding: "2rem 0"}}></p>

            <Drop name={'2 неделя'} edit={false} drop={() => {
                return (
                    <div className={'work-time-table'}>
                        <img style={{width: "100%"}} src={table_info_img}/>
                    </div>
                )
            }}
            />
            <p style={{padding: "2rem 0"}}></p>

            <Drop name={'3 неделя'} edit={false} drop={() => {
                return (
                    <div className={'work-time-table'}>
                        <img style={{width: "100%"}} src={table_info_img}/>
                    </div>
                )
            }}
            />
            <p style={{padding: "2rem 0"}}></p>
            <Drop name={'4 неделя'} edit={false} drop={() => {
                return (
                    <div className={'work-time-table'}>
                        <img style={{width: "100%"}} src={table_info_img}/>
                    </div>
                )
            }}
            />
        </div>
    );

}

export default Empty;