import './scss/index.scss';

import React, {SetStateAction, useEffect, useState} from "react";


import {useSelector} from "react-redux";
import StateSlices from "../../redux/slices/State";
import Layout from "../../components/Layout";


import naimSvg1 from "./assets/img/naim.svg";
import InputMini from "../../components/InputMini";
import Drop from "../personal/components/Drop";


export default function AboutRoute() {

    const state_in = useSelector(StateSlices);

    const [step, setStep] = useState(0);


    return (
        <Layout headTitle={(
            <div className={'text-head-box'}>
                <span style={{width: "18.5rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    Главное меню
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text   text-gray text-s20'}>
                    Подбор персонала
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text  text-gray  text-s20'}>
                    /
                </p>
                <span style={{width: "1rem"}}></span>
                <p className={'text text-un text-bly text-s20'}>
                    Программа найма
                </p>

            </div>
        )}>

            <div className="console console-mini">
                <div className="console-left">
                    <div className="console-name">
                        <b className={'text text-s36'}>
                            Программа найма
                        </b>
                    </div>
                </div>
            </div>

            <img src={naimSvg1} style={{width: "100%"}}/>

        </Layout>
    );


}