import './scss/index.scss';
import Drop from "../Drop";
import CheckBox from "../../../../components/CheckBox";

interface Props {
    name: string;
}

const Empty: React.FC<Props> = ({name}) => {


    return (
        <>
            <div className={'work-box'}>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Дата приема</div>
                        <div className="work-items-value text text-gray text-s14">01.01.2024</div>
                    </div>
                </div>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14"> Начало испытательного срока</div>
                        <div className="work-items-value text text-gray text-s14">01.01.2024</div>
                    </div>
                </div>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14"> Конец испытательного срока</div>
                        <div className="work-items-value text text-gray text-s14">01.01.2024</div>
                    </div>
                </div>


            </div>
            <div className={'work-box'}>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Положенные дни отпуска</div>
                        <div className="work-items-value text text-gray text-s14">01.01.2024</div>
                    </div>
                </div>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Начало трудового договора</div>
                        <div className="work-items-value text text-gray text-s14">01.01.2024</div>
                    </div>
                </div>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Конец трудового договора</div>
                        <div className="work-items-value text text-gray text-s14">01.01.2024</div>
                    </div>
                </div>
            </div>

            <div className={'work-box'}>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Руководитель</div>
                        <div className="work-items-value text text-gray text-s14">Выберите</div>
                    </div>
                </div>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Сотрудники
                            в подчинении
                        </div>
                        <div className="work-items-value text text-gray text-s14">Выберите</div>
                    </div>
                </div>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Подразделение</div>
                        <div className="work-items-value text text-gray text-s14">Выберите</div>
                    </div>
                </div>
            </div>


            <p style={{marginTop: "2.4rem"}}></p>
            <p className={'text text-s24'}>
                <b>Текущие условия</b>
            </p>
            <div className={'work-box'}>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Зарплата</div>
                        <div className="work-items-value text text-gray text-s14">250000</div>
                    </div>
                </div>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Оклад
                        </div>
                        <div className="work-items-value text text-gray text-s14">200 000</div>
                    </div>
                </div>
                <div className="work">
                    <div className="work-items">
                        <div className="work-items-name text text-s14">Надбавка</div>
                        <div className="work-items-value text text-gray text-s14">150000</div>
                    </div>
                </div>
            </div>


            <p style={{marginTop: "2.4rem"}}></p>
            <p className={'text text-s24'}>
                <b>Условия ранее</b>
            </p>
            <div className={'work-box'}>

                <Drop edit={false} name={'2023 год'} drop={() => {
                    return (
                        <div className={'work-box-drop'}>
                            <div className="work">
                                <div className="work-items">
                                    <div className="work-items-name text text-s14">Зарплата</div>
                                    <div className="work-items-value text text-gray text-s14">250000</div>
                                </div>
                            </div>
                            <div className="work">
                                <div className="work-items">
                                    <div className="work-items-name text text-s14">Оклад
                                    </div>
                                    <div className="work-items-value text text-gray text-s14">200 000</div>
                                </div>
                            </div>
                            <div className="work">
                                <div className="work-items">
                                    <div className="work-items-name text text-s14">Надбавка</div>
                                    <div className="work-items-value text text-gray text-s14">150000</div>
                                </div>
                            </div>
                        </div>
                    );
                }}/>
            </div>

            <div className={'work-box'}>
                <Drop edit={false} name={'2022 год'} drop={() => {
                    return (
                        <div className={'work-box-drop'}>
                            <div className="work">
                                <div className="work-items">
                                    <div className="work-items-name text text-s14">Зарплата</div>
                                    <div className="work-items-value text text-gray text-s14">250000</div>
                                </div>
                            </div>
                            <div className="work">
                                <div className="work-items">
                                    <div className="work-items-name text text-s14">Оклад
                                    </div>
                                    <div className="work-items-value text text-gray text-s14">200 000</div>
                                </div>
                            </div>
                            <div className="work">
                                <div className="work-items">
                                    <div className="work-items-name text text-s14">Надбавка</div>
                                    <div className="work-items-value text text-gray text-s14">150000</div>
                                </div>
                            </div>
                        </div>
                    );
                }}/>
            </div>

            <p style={{marginTop: "2.4rem"}}></p>
            <p className={'text text-s24'}>
                <b>Социальный пакет и бенефиты</b>
            </p>
            <div className={'work-box'}>
                <div className="check-box">
                    <p className={' text text-s14'}>
                        <b> Служебное авто</b>
                    </p>
                    <CheckBox active={true}/>
                </div>

                <div className="check-box">
                    <p className={' text text-s14'}>
                        <b> ДМС</b>
                    </p>
                    <CheckBox active={false}/>
                </div>

                <div className="check-box">
                    <p className={' text text-s14'}>
                        <b> Спортивный абонемент</b>
                    </p>
                    <CheckBox active={false}/>
                </div>

                <div className="check-box">
                    <p className={' text text-s14'}>
                        <b> Корп. english</b>
                    </p>
                    <CheckBox active={false}/>
                </div>

                <div className="check-box">
                    <p className={' text text-s14'}>
                        <b> Корп. связь</b>
                    </p>
                    <CheckBox active={false}/>
                </div>

                <div className="check-box">
                    <p className={' text text-s14'}>
                        <b> Аренда жилья</b>
                    </p>
                    <CheckBox active={false}/>
                </div>

                <div className="check-box">
                    <p className={' text text-s14'}>
                        <b> Мед. страховка</b>
                    </p>
                    <CheckBox active={false}/>
                </div>


            </div>

        </>
    );

}

export default Empty;